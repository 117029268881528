import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';

export function usePermission(permission?: string): boolean {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();
  const { permissions } = currentCompanyProfileUser;

  return permission
    ? permissions.some(
        (companyProfileUserPermission) =>
          companyProfileUserPermission.name === permission
      )
    : true;
}

export function usePermissions(permissions: string[]): boolean {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  if (!permissions) return false;

  const currentUserPermissions = new Set(
    currentCompanyProfileUser.permissions.map((p) => p.name)
  );

  // Maybe not ideal but just in case
  return Array.isArray(permissions)
    ? permissions.every((p) => currentUserPermissions.has(p))
    : currentUserPermissions.has(permissions);
}

export const Permissions = {
  commsEmailsAdmin: 'comms_emails.admin',
  companiesBoardReportsAdmin: 'companies_board_reports.admin',
  companiesProfileUsersAdmin: 'companies_profile_users.admin',
  contactsDynamicListsAdmin: 'contacts_dynamic_lists.admin',
  interactionsMediaAnnouncementsAdmin: 'interactions_media_announcements.admin',
  interactionsMediaAnnouncementsEditor:
    'interactions_media_announcements.editor',
  interactionsMediaUpdatesAdmin: 'interactions_media_updates.admin',
  registersShareholdingsAdmin: 'registers_shareholdings.admin',
  shareholderOfferAdmin: 'shareholder_offer.admin',
  webinarsAdmin: 'webinars.admin',
  websitesAdmin: 'websites.admin',
};

export interface CompanyRole {
  description: string;
  id: string;
  key: string;
  name: string;
  order: number;
}

export const CompanyRoles = {
  admin: {
    description:
      'Admins have full view and edit access, including organisation settings, inviting other users, launching retail offer pages, and reviewing past placement reports.',
    name: 'Admin',
    order: 1,
  },
  basic: {
    description:
      'Has basic access to published campaigns, contact information, and engagement analytics. Cannot see any private registry data or draft content.',
    name: 'Basic',
    order: 5,
  },
  content: {
    description:
      "Can create and edit campaigns, announcements, updates, and social posts. Can modify the Hub's appearance, customise campaign templates, and see content performance.",
    name: 'Content',
    order: 4,
  },
  content_sensitive: {
    description:
      "Can create and edit campaigns, announcements, updates, and social posts. Can create and modify draft announcements. Can modify the Hub's appearance, customise campaign templates, and see content performance.",
    name: 'Content (sensitive)',
    order: 3,
  },
  registry: {
    description:
      'Can see all shareholder data, including individual accounts and movements. Can create Raises and manage past placements.',
    name: 'Registry',
    order: 2,
  },
};

export default usePermission;
