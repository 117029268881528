const locales = ['en-AU', 'en-UK'];
type ValidLocale = (typeof locales)[number];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dictionaries: Record<ValidLocale, any> = {
  'en-AU': require('translations/en-AU.json'),
  'en-UK': require('translations/en-UK.json'),
};

export const getTranslator = (locale: ValidLocale | null) => {
  if (!locale || !dictionaries[locale]) {
    return (_key: string) => '';
  }
  const dictionary = dictionaries[locale];
  return (key: string, params?: { [key: string]: string | number }) => {
    let translation = key
      .split('.')
      .reduce((obj, key) => obj && obj[key], dictionary);
    if (!translation) {
      return key;
    }
    // If a translation has an object like:
    // "See the full list of covered brokers {{text: 'here', link: 'https://help.investorhub.com/en/articles/10534638'}}.",
    // replace it like: "See the full list of covered brokers <a href='https://help.investorhub.com/en/articles/10534638'>here</a>."
    const linkRegex = /{{\s*text:\s*'([^']+)',\s*link:\s*'([^']+)'\s*}}/g;
    translation = translation.replace(
      linkRegex,
      (_match: string, text: string, link: string): string => {
        return `<a href='${link}' class='text-amplify-green-600 underline' target='_blank'>${text}</a>`;
      }
    );

    if (params && Object.entries(params).length) {
      Object.entries(params).forEach(([key, value]) => {
        translation = translation
          ? translation.replace(`{{ ${key} }}`, String(value))
          : 'unknown translation';
      });
    }
    return translation;
  };
};
