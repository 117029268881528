import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';

function useFeatureEnabled(feature: string): boolean {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();
  const { featuresEnabled } = currentCompanyProfileUser.profile;

  return featuresEnabled.includes(feature);
}

export const FLAGS = {
  activityLogUplift: 'activity_log_uplift',
  aiAnswer: 'ai_answer',
  beneficialOwnersReport: 'beneficial_owners_report',
  beneficialOwnersReportsMenu: 'beneficial_owners_reports_menu',
  extraSharePrices: 'extra_share_prices',
  featuredAnnouncements: 'featured_announcements',
  germanAnnouncementTranslations: 'german_announcement_translations',
  investorCommsPostcard: 'investor_comms_postcard',
  keyMetrics: 'key_metrics',
  paymentOverdue: 'payment_overdue',
  preparedAnnouncements: 'prepared_announcements',
  registryUpsellPages: 'registry_upsell_pages',
  segmentation: 'segmentation',
  sppCalculator: 'spp_calculator',
  sppEstimation: 'spp_estimation',
  staticLists: 'static_lists',
  sunriceGrowerNumber: 'sunrice_grower_number',
  teamAccessLevelSelector: 'team_access_level_selector',
  trackEmail: 'track_email',
  webinars: 'webinars_enabled',
  websitePageBuilder: 'website_page_builder',
};

export default useFeatureEnabled;
