export const fetchOptions = { revalidateOnFocus: false };

export default async function fetcher(args: RequestInfo) {
  const res = await fetch(args);

  if (!res.ok) {
    // Attach extra info to the error object.
    const response: { error: string } = await res.json();
    const error = new Error(response.error || 'Unknown error');
    error.name = 'An error occurred retrieving the data';
    throw error;
  }

  return res.json();
}
