export const baseUrl = 'https://api.rkd.refinitiv.com/api';

interface RefinitivQuoteField {
  DataType: 'Double' | 'Utf8String' | 'Int64';
  Double?: number;
  Int64?: number;
  Name: string;
  Utf8String?: string;
}

export interface RefinitivQuoteData {
  Fields: RefinitivQuoteField[];
}

export function getFieldValue(d: RefinitivQuoteData | undefined, name: string) {
  if (!d) {
    return '-';
  }
  const value = d.Fields.find((field) => field.Name === name);
  const returnVal = value ? value[value.DataType] : '-';
  return returnVal === 0 || returnVal === '0' ? '-' : returnVal;
}
